import React, { useState } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import css from 'react-syntax-highlighter/dist/esm/languages/hljs/css';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
import cpp from 'react-syntax-highlighter/dist/esm/languages/hljs/cpp';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
import csharp from 'react-syntax-highlighter/dist/esm/languages/hljs/csharp';

import { androidstudio } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styled from "styled-components";

const CodeBlockContainer = styled.div`
  background-color: #1e1e1e;
  color: #dcdcdc;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  overflow-x: auto;
  font-family: "Consolas", "Courier New", monospace;
  margin: 10px 0;
`;

const Header = styled.div`
  background-color: #2b2b2b;
  color: #dcdcdc;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  margin: -15px -15px 10px -15px;
`;

const CopyButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #2b2b2b;
  color: #ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background-color: #3d3d3d;
    color: #fff;
  }
`;

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('css', css);
SyntaxHighlighter.registerLanguage('html', html);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('cpp', cpp);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('sql', sql);
SyntaxHighlighter.registerLanguage('csharp', csharp);

const CodeBlock = ({ code, language }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <CodeBlockContainer>
      <Header>{language.toUpperCase()}</Header>
      <CopyButton onClick={handleCopy}>
        {copied ? "Copied!" : "Copy code"}
      </CopyButton>
      <SyntaxHighlighter 
        language={language} 
        style={androidstudio} 
        showLineNumbers>
        {code}
      </SyntaxHighlighter>
    </CodeBlockContainer>
  );
};

export default CodeBlock;
