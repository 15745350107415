import {
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE,
    STREAM_MESSAGE_UPDATE,
    STREAM_MESSAGE_DONE,
} from "../actionTypes";

const initialState = {
    messages: [],
    loading: false,
    error: null,
    isStreaming: false,
    isTyping: false
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_MESSAGE_REQUEST:
            return { ...state, loading: true, error: null, isTyping: true, };

        case SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: [...state.messages, action.payload],
                isTyping: false,
            };

        case STREAM_MESSAGE_UPDATE:
            if (action.payload.initial) {
                return {
                    ...state,
                    isStreaming: true,
                    messages: [...state.messages, { role: "system", content: "" }],
                };
            } else {
                return {
                    ...state,
                    messages: state.messages.map((msg, index) =>
                        index === state.messages.length - 1
                            ? { ...msg, content: msg.content + action.payload.content }
                            : msg
                    ),
                };
            }

        case STREAM_MESSAGE_DONE:
            return { ...state, isStreaming: false, loading: false };

        case SEND_MESSAGE_FAILURE:
            return { ...state, loading: false, error: action.payload, isTyping: false, };

        default:
            return state;
    }
};

export default messageReducer;