import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendMessage, streamMessage } from "../state/messages/actions";
import './Chatbox.css';
import CodeBlock from "./ui/CodeBlock";

const Chatbox = () => {
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();
  const { messages, loading, error, isStreaming } = useSelector((state) => state.messages);

  const contentEditableRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
      event.preventDefault();
    }
  };

  useEffect(() => {
    const chatContainer = document.getElementById("chatbox");
    if (chatContainer) {
      if (messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.role === 'system') {
          setIsTyping(false);
        } else {
          setIsTyping(true);
        }
      }

      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (input.trim()) {
      dispatch(sendMessage(input));
      setInput("");
      contentEditableRef.current.innerHTML = "";
    }
  };

  const handleStreamMessage = () => {
    if (input.trim()) {
      dispatch(streamMessage(input));
      setInput("");
    }
  };

  // Parse code blocks and return an array of either text segments or CodeBlock components
  const splitIntoCodeBlocks = (content) => {
    const parts = [];
    let currentIndex = 0;

    while (true) {
      const startIndex = content.indexOf("```", currentIndex);
      if (startIndex === -1) {
        // No more code blocks
        const remainingText = content.slice(currentIndex);
        if (remainingText) {
          parts.push({ type: "text", content: remainingText });
        }
        break;
      }

      // Push any text before this code block
      if (startIndex > currentIndex) {
        const textBefore = content.slice(currentIndex, startIndex);
        if (textBefore) {
          parts.push({ type: "text", content: textBefore });
        }
      }

      // Find the end of the code block
      const endIndex = content.indexOf("```", startIndex + 3);
      if (endIndex === -1) {
        // No closing code block found, treat the rest as code block
        const codeBlockContent = content.slice(startIndex + 3);
        parts.push({ type: "code", content: codeBlockContent, language: "" });
        break;
      } else {
        const codeBlockFull = content.slice(startIndex + 3, endIndex);
        // The first line of codeBlockFull might contain the language
        const firstLineBreak = codeBlockFull.indexOf("\n");
        let language = "";
        let codeContent = codeBlockFull;
        if (firstLineBreak !== -1) {
          const maybeLang = codeBlockFull.slice(0, firstLineBreak).trim();
          // Heuristic: If the first line is a single word, treat it as language
          // Otherwise assume no language
          if (maybeLang && !maybeLang.includes(" ")) {
            language = maybeLang;
            codeContent = codeBlockFull.slice(firstLineBreak + 1);
          }
        }

        parts.push({ type: "code", content: codeContent, language });
        currentIndex = endIndex + 3;
      }
    }

    return parts;
  };

  const formatMessageContent = (content) => {
    const parts = splitIntoCodeBlocks(content);
    const elements = [];

    parts.forEach((part, idx) => {
      if (part.type === "text") {
        elements.push(...formatText(part.content, `text-${idx}`));
      } else if (part.type === "code") {
        elements.push(
          <CodeBlock
            key={`code-${idx}`}
            code={part.content.trim()}
            language={part.language || "js"}
          />
        );
      }
    });

    return elements;
  };

  const formatText = (text, baseKey) => {
    const lines = text.split("\n");
    const lineElements = [];

    lines.forEach((line, lineIndex) => {
      const headingElement = tryFormatHeading(line, `${baseKey}-line-${lineIndex}`);
      if (headingElement) {
        lineElements.push(headingElement);
      } else {
        const inlineElements = formatInlineStyles(line, `${baseKey}-inline-${lineIndex}`);

        lineElements.push(
          <span key={`${baseKey}-lineContainer-${lineIndex}`} style={{ display: "block" }}>
            {inlineElements}
          </span>
        );
      }
    });

    return lineElements;
  };

  const tryFormatHeading = (line, key) => {

    let hashCount = 0;
    for (let i = 0; i < line.length; i++) {
      if (line[i] === '#') {
        hashCount++;
      } else {
        break;
      }
    }

    if (hashCount > 0 && hashCount <= 6) {
      if (line.length > hashCount && line[hashCount] === ' ') {
        const headingText = line.slice(hashCount + 1);
        const HeadingTag = `h${hashCount}`;
        return React.createElement(HeadingTag, { key: key }, headingText);
      }
    }

    return null;
  };

  const formatInlineStyles = (line, key) => {
    let result = [];
    let currentText = "";
    let bold = false;
    let italic = false;
    let i = 0;

    const pushSegment = () => {
      if (currentText) {
        let element = currentText;
        if (bold && italic) {
          element = <span style={{ fontWeight: "bold", fontStyle: "italic" }} key={`${key}-seg-${i}-${Math.random()}`}>{currentText}</span>;
        } else if (bold) {
          element = <b key={`${key}-seg-${i}-${Math.random()}`}>{currentText}</b>;
        } else if (italic) {
          element = <i key={`${key}-seg-${i}-${Math.random()}`}>{currentText}</i>;
        } else {
          element = <span key={`${key}-seg-${i}-${Math.random()}`}>{currentText}</span>;
        }
        result.push(element);
        currentText = "";
      }
    };

    while (i < line.length) {
      if (!italic && line[i] === '*' && i + 1 < line.length && line[i + 1] === '*') {
        pushSegment();
        bold = !bold;
        i += 2;
      } else if (line[i] === '_') {
        pushSegment();
        italic = !italic;
        i++;
      } else {
        currentText += line[i];
        i++;
      }
    }

    pushSegment();

    return result;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "95vh",
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "10px"
      }}
    >
      <div
        id="chatbox"
        className="chatbox"
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.role === "user" ? "message-user" : "message-ava"}`}
          >
            <b className={`role ${msg.role === "user" ? "user-role" : "ava-role"}`}>
              {msg.role === "user" ? "You" : "Ava"}:
            </b>{" "}
            {formatMessageContent(msg.content)}
          </div>
        ))}
        {loading && <div className="loading">Loading...</div>}
        {isStreaming && <div className="streaming">Streaming...</div>}
        {error && <div className="error">{error}</div>}
        {isTyping && (
          <>
            <b className={"role ava-role"}>
              {"Ava"}:
            </b>{" "}
            <div className="typing-indicator">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </>
        )}
      </div>

      <div style={{ display: "flex", marginTop: "10px", position: "relative" }}>
        <div
          ref={contentEditableRef}
          contentEditable
          onInput={(e) => setInput(e.currentTarget.innerHTML)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
          style={{
            flex: 1,
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #444",
            backgroundColor: "#333",
            color: "white",
            minHeight: "40px",
            overflowY: "auto"
          }}
        />
        <span
          onClick={handleSendMessage}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer"
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            fill="white"
            viewBox="0 0 24 24"
          >
            <path d="M12 12.713l8.584-6.679c.54-.42 1.416-.118 1.416.675V20.91C22 21.68 21.176 22 20.499 22H3.501C2.824 22 2 21.68 2 20.91V6.709c0-.792.872-1.094 1.413-.675L12 12.713z" />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default Chatbox;
