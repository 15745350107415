import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Career from "./components/Career";
import Chatbox from "./components/ChatBox";
import { Provider } from "react-redux";
import store from "./state/store";

export default function App() {
  return (
    <Router>
      <Provider store={store}>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <main className="text-gray-400 bg-gray-900 body-font">
              <About />
              <Career />
              <Projects />
              <Skills />
              <Contact />
            </main>
          } />
          
          <Route path="/ava" element={
            <main className="text-gray-400 bg-gray-900 body-font">
              <Chatbox />
            </main>
          } />
        </Routes>
      </Provider>
    </Router>
  );
}
