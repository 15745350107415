import {
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE,
    STREAM_MESSAGE_UPDATE,
    STREAM_MESSAGE_DONE,
} from "../actionTypes";

import { API_BASE_URI } from "../../AppConstants";

export const sendMessage = (message) => async (dispatch) => {
    dispatch({ type: SEND_MESSAGE_REQUEST });

    try {
        dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: { role: "user", content: message },
        });
        
        const response = await fetch(`${API_BASE_URI}/message`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(message),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText || "Failed to send message");
        }

        const data = await response.json();

        dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: { role: "system", content: data.response },
        });

    } catch (error) {
        dispatch({ type: SEND_MESSAGE_FAILURE, payload: error.message });
    }
};

export const streamMessage = (message) => async (dispatch) => {
    dispatch({ type: SEND_MESSAGE_REQUEST });

    try {
        const response = await fetch(`${API_BASE_URI}/message/stream-message`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(message),
        });

        if (!response.body) throw new Error("No response body for streaming message.");

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        dispatch({
            type: STREAM_MESSAGE_UPDATE,
            payload: { initial: true, content: "" },
        });

        let partialContent = "";

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            partialContent += chunk;

            const lines = partialContent.split("\n\n");
            for (let i = 0; i < lines.length - 1; i++) {
                const line = lines[i];
                if (line.startsWith("data:")) {
                    const cleanedChunk = line.replace(/^data:/, "");
                    dispatch({
                        type: STREAM_MESSAGE_UPDATE,
                        payload: { initial: false, content: cleanedChunk },
                    });
                }
            }

            partialContent = lines[lines.length - 1];
        }

        dispatch({ type: STREAM_MESSAGE_DONE });
    } catch (error) {
        dispatch({ type: SEND_MESSAGE_FAILURE, payload: error.message });
    }
};
